import React, { useState, useEffect } from "react";
import "./home.css";
import "./home.animations.css";
import Meniu from "../meniu/meniu";
import Reserve from "../reserve/reserve";

export default function Home() {
    const [isMenuVisible, setMenuVisible] = useState(false);
    const [isReserveVisible, setReserveVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const showMeniu = () => {
        setMenuVisible(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const hideMeniu = () => {
        setMenuVisible(false);
    };

    const showReserve = () => {
        setReserveVisible(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const hideReserve = () => {
        setReserveVisible(false);
    };

    useEffect(() => {
        if (isMenuVisible) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    }, [isMenuVisible]);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 768px)");

        const handleResize = (e) => {
            setIsMobile(e.matches);
        };

        mediaQuery.addEventListener("change", handleResize);

        // Set the initial state
        setIsMobile(mediaQuery.matches);

        // Cleanup event listener on unmount
        return () => {
            mediaQuery.removeEventListener("change", handleResize);
        };
    }, []);

    const backgroundImage = isMobile
        ? "/images/Hero_bg_mobile.avif"
        : "/images/Hero_bg.avif";

    return (
        <div className="home_hero">
            <Meniu isVisible={isMenuVisible} hideMeniu={hideMeniu} />
            <Reserve isVisible={isReserveVisible} hideReserve={hideReserve} />
            <div
                className="hh_bg"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                }}
            >
                <div className="hh_content">
                    <div className="hh_social">
                        <a href="https://www.facebook.com/profile.php?id=61566595971602" target="_blank" rel="noreferrer">FACEBOOK</a>
                        <a href="https://www.instagram.com/niekotokio.catering" target="_blank" rel="noreferrer">INSTAGRAM</a>
                    </div>
                    <div className="hh_title">
                        <h2>Jau netrukus pasimatysime!</h2>
                        <h1>Japonijos įkvėptoje edukacinėje gastro kelionėje, jūsų namuose</h1>
                    </div>
                    <div className="hh_mail">
                        <a href="mailto:labas@niekotokio.catering">labas@niekotokio.catering</a>
                    </div>
                </div>
                <div className="hh_bottom">
                    <div className="hh_meniu" onClick={showMeniu}>
                        <img src="/images/buttons/meniu_button.webp" alt="meniu" loading="lazy" />
                    </div>
                    <div className="hh_reserve" onClick={showReserve}>
                        <img src="/images/buttons/reserve_button.webp" alt="reserve" loading="lazy" />
                    </div>
                </div>

                <div className="hh_shrimp">
                    <picture>
                        <source media="(max-width: 768px)" srcSet="/images/shrimp_mobile.webp" type="image/webp" />
                        <img className="desk_shrimp" src="/images/shrimp.avif" alt="shrimp" draggable="false" loading="lazy" />
                    </picture>
                </div>
                <div className="hh_koi">
                    <picture>
                        <source media="(max-width: 768px)" srcSet="/images/koi_mobile.webp" type="image/webp" />
                        <img className="desk_koi" src="/images/koi.avif" alt="koi" draggable="false" loading="lazy" />
                    </picture>
                </div>
            </div>
            <div className="hh_mobile_content">
                <div className="hh_social">
                    <a href="https://www.facebook.com/profile.php?id=61566595971602">FACEBOOK</a>
                    <a href="https://www.instagram.com/niekotokio.catering">INSTAGRAM</a>
                </div>
                <div className="hh_mail">
                    <a href="mailto:labas@niekotokio.catering">labas@niekotokio.catering</a>
                </div>
            </div>
        </div>
    );
}
